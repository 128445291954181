<template>
  <div v-if="permission.access">
    <div class="w-100 d-flex align-items-center justify-content-between mb-1">
      <div>
        <label>Show</label>
        <v-select
          @on-change="paginateChange"
          :options="perPageOptions"
          v-model="search.paginate"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
      </div>
      <b-dropdown
        v-if="permission.delete"
        text="Action"
        variant="outline-primary"
        :disabled="!select.selected"
      >
        <b-dropdown-item @click="Delete"> Delete </b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted"
          >Showing {{ projects.from ? projects.from : 0 }} to
          {{ projects.to ? projects.to : 0 }} of
          {{ projects.total ? projects.total : 0 }}
          entries</span
        >
      </b-col>

      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <pagination
          :data="projects"
          @pagination-change-page="getResults"
          align="right"
          :show-disabled="true"
          :limit="5"
        ></pagination>
      </b-col>
    </b-row> -->
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div
            class="col-md-6 mb-2"
            v-for="(project, index) in projects.data"
            :key="index"
            v-if="projects.data.length"
          >
            <b-card class="card-app-design">
              <b-badge variant="light-primary">
                {{ project.created_at | date_time_format }}
              </b-badge>
              <b-card-title class="mt-1 mb-75">
                {{ project.title }}
              </b-card-title>
              <b-card-text class="font-small-2 mb-2">
                {{ project.description }}
              </b-card-text>

              <!-- design group -->
              <div class="design-group">
                <h6 class="section-label">Team</h6>
                <b-badge
                  style="margin-right: 5px; margin-bottom: 4px"
                  v-for="(department, index_department) in project.departments"
                  :key="index_department"
                  :variant="index_department % 2 == 1 ? 'success' : 'primary'"
                  v-if="project.departments.length"
                >
                  {{ department.name }}
                </b-badge>
              </div>

              <div class="design-group">
                <h6 class="section-label">Members</h6>
                <!-- :src="
                    require('@/assets/images/portrait/small/avatar-s-20.jpg')
                  " -->
                <b-avatar
                  v-for="(employee, employee_index) in project.employees"
                  :key="employee_index"
                  v-if="project.employees.length"
                  class="mr-1"
                  variant="secondary"
                  v-b-tooltip.hover
                  :title="employee.name"
                  :text="getChar(employee.name)"
                />
              </div>
              <!--/ design group -->
              <div class="design-group">
                <h6 class="section-label">Status</h6>
                <b-badge
                  style="margin-right: 5px"
                  :variant="
                    project.priority == 1
                      ? 'danger'
                      : project.priority == 2
                      ? 'primary'
                      : 'warning'
                  "
                >
                  {{
                    project.priority == 1
                      ? "High Priority"
                      : project.priority == 2
                      ? "Medium Priority"
                      : "Low Priority"
                  }}
                </b-badge>
                <b-badge
                  style="margin-right: 5px"
                  :variant="
                    project.is_progress == 1
                      ? 'warning'
                      : project.is_progress == 2
                      ? 'primary'
                      : project.is_progress == 3
                      ? 'success'
                      : 'danger'
                  "
                >
                  {{
                    project.is_progress == 1
                      ? "Not Started"
                      : project.is_progress == 2
                      ? "On Going"
                      : project.is_progress == 3
                      ? "Finished"
                      : "Cancel"
                  }}
                </b-badge>
                <div style="margin-top: 3px">
                  <Progress
                    :percent="
                      getPercentage(project.total_task, project.total_complete)
                    "
                    :stroke-width="8"
                  />
                </div>
              </div>

              <div class="design-planning-wrapper">
                <div class="design-planning">
                  <p class="card-text mb-25">Start Date</p>
                  <h6 class="mb-0">
                    {{ project.start_date | date_time_format_short }}
                  </h6>
                </div>
                <div class="design-planning">
                  <p class="card-text mb-25">Due Date</p>
                  <h6 class="mb-0">
                    {{ project.end_date | date_time_format_short }}
                  </h6>
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div style="min-width: 75%">
                  <b-button
                    block
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :to="'/projects/' + project.id"
                  >
                    Go To Details
                  </b-button>
                </div>
                <b-button
                  v-if="permission.edit"
                  @click="
                    edit(
                      project.id,
                      getPercentage(project.total_task, project.total_complete)
                    )
                  "
                  :disabled="
                    getPercentage(project.total_task, project.total_complete) >=
                    100
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </div>
            </b-card>
          </div>
        </div>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ projects.from ? projects.from : 0 }} to
              {{ projects.to ? projects.to : 0 }} of
              {{ projects.total ? projects.total : 0 }}
              entries</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <pagination
              :data="projects"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Project'">
          <b-card-text>
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row mb-2">
                  <div class="col-md-12">
                    <b-form-group label="Project Title" label-for="title">
                      <validation-provider
                        #default="{ errors }"
                        name="Project Title"
                        rules="required"
                      >
                        <Input
                          name="title"
                          type="text"
                          v-model="form.title"
                          id="title"
                          placeholder="Enter Project Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Start Date" label-for="start_date">
                      <validation-provider
                        #default="{ errors }"
                        name="Start Date"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="form.start_date"
                          class="form-control"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Select Start Date"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i',
                          }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="End Date" label-for="end_date">
                      <validation-provider
                        #default="{ errors }"
                        name="End Date"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="form.end_date"
                          class="form-control"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Select End Date"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i',
                          }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Select Priority" label-for="priority">
                      <validation-provider
                        #default="{ errors }"
                        name="Priority"
                        rules="required"
                      >
                        <Select
                          v-model="form.priority"
                          filterable
                          name="priority"
                          id="priority"
                          placeholder="Select Priority"
                        >
                          <Option :value="1">High</Option>
                          <Option :value="2">Medium</Option>
                          <Option :value="3">Low</Option>
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12" v-if="form.done_percentage < 1">
                    <b-form-group label="Is Progress" label-for="is_progress">
                      <validation-provider
                        #default="{ errors }"
                        name="is_progress"
                      >
                        <Select
                          v-model="form.is_progress"
                          filterable
                          name="is_progress"
                          id="is_progress"
                          placeholder="Is Progress"
                        >
                          <Option :value="1">Not Started</Option>
                          <Option :value="2">On Going</Option>
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Select Department"
                      label-for="department_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Department"
                        rules="required"
                      >
                        <Select
                          multiple
                          @on-change="getEmployee"
                          v-model="form.department_id"
                          filterable
                          name="department_id"
                          id="department_id"
                          placeholder="Select Department"
                        >
                          <Option
                            :value="department.id"
                            v-for="(department, index) in departments"
                            :key="index"
                            v-if="departments.length"
                            >{{ department.name }}</Option
                          >
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Select Employee"
                      label-for="employee_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Employee"
                        rules="required"
                      >
                        <Select
                          multiple
                          v-model="form.employee_id"
                          filterable
                          name="employee_id"
                          id="employee_id"
                          placeholder="Select Employee"
                        >
                          <Option
                            :value="employee.id"
                            v-for="(employee, index) in employees"
                            :key="index"
                            v-if="employees.length"
                            >{{ employee.name }}</Option
                          >
                        </Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Select Description"
                      label-for="description"
                      rules="required"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                      >
                        <Input
                          v-model="form.description"
                          type="textarea"
                          :rows="3"
                          placeholder="Enter Your Description"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                  Status
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BBadge,
  BAvatar,
  BDropdownDivider,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      selected: [],
      search: {
        work_type: null,
        status: null,
        department_id: [],
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [6, 10, 20, 100],
      form: {
        id: "",
        title: null,
        start_date: null,
        end_date: null,
        priority: null,
        is_progress: null,
        done_percentage: null,
        department_id: [],
        employee_id: [],
        description: null,
        status: true,
      },
      employees: {},
      projects: {},

      select: {
        data: [],
        selected: false,
      },
      selectedAll: false,
    };
  },
  mounted() {
    this.getEmployee();
    this.$store.dispatch("getDepartment");
    this.$store.dispatch("getTasks");
    this.getResults();
  },
  methods: {
    getPercentage(total, complete) {
      if (total > 0) {
        return Math.round((complete / total) * 100);
      } else {
        return 0;
      }
    },
    getChar(text) {
      return text
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
        .substring(0, 3);
    },
    async getEmployee() {
      this.employees = {};
      const employees = await this.callApi(
        "post",
        "/project/get/department/employee/get",
        this.form
      );
      if (employees.status == 200) {
        this.employees = employees.data.employees;
      }
    },
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const projects = await this.callApi(
        "post",
        "/project?page=" + this.search.page,
        this.search
      );
      if (projects.status == 200) {
        this.projects = projects.data.projects;
      }
    },

    Delete() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteAlert("/project/default/tasks/delete", this.select);
          this.select.data = [];
          this.getResults();
        }
      });
    },

    Close() {
      this.form.id = "";
      this.form.title = null;
      this.form.start_date = null;
      this.form.end_date = null;
      this.form.priority = null;
      this.form.is_progress = null;
      this.form.done_percentage = null;
      this.form.department_id = [];
      this.form.employee_id = [];
      this.form.description = null;
      this.form.status = true;
    },

    async edit(id, done_percentage) {
      this.Close();
      const project = await this.callApi("post", "/project/edit/" + id);
      if (project.status == 200) {
        this.form.id = project.data.project.id;
        this.form.title = project.data.project.title;
        this.form.done_percentage = done_percentage;
        this.form.start_date = project.data.project.start_date;
        this.form.end_date = project.data.project.end_date;
        this.form.priority = project.data.project.priority;
        this.form.is_progress = project.data.project.is_progress;
        this.form.description = project.data.project.description;
        let department_id = [];
        project.data.project.departments.forEach((data) => {
          department_id.push(data.department_id);
        });
        this.form.department_id = department_id;
        project.data.project.employees.forEach((data) => {
          this.form.employee_id.push(data.employee_id);
        });

        this.form.status = project.data.project.status == true ? true : false;
      }
    },

    add() {
      axios
        .post("/project/store", this.form)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
          this.Close();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
            if (e.response.data.errors.priority)
              this.e(e.response.data.errors.priority[0]);
            if (e.response.data.errors.is_progress)
              this.e(e.response.data.errors.is_progress[0]);
            if (e.response.data.errors.department_id)
              this.e(e.response.data.errors.department_id[0]);
            if (e.response.data.errors.employee_id)
              this.e(e.response.data.errors.employee_id[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.projects.data.forEach((category) => {
          this.select.data.push(category.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "select.data": function (selected) {
      this.select.selected = selected.length > 0;
      this.selectedAll = selected.length == this.projects.data.length;
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    flatPickr,
    BBadge,
    BAvatar,
    VBTooltip,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  computed: {
    departments() {
      return this.$store.getters.getDepartment;
    },
    default_tasks() {
      return this.$store.getters.getTasks;
    },
    permission() {
      return this.$store.getters.getPermissions.project;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
